<template>
  <v-container class="page-container">
    <v-card flat outlined class="pt-12 pb-5 mb-5 text-center" color="#f9f9f9">
      <div class="text-h2 pb-2">Young opinions you can trust</div>
      <elements-typing-fx />
      <elements-search-box />
    </v-card>

    <v-card flat outlined class="pa-4 mb-5">
      <div class="text-h3">Your questions</div>
      <div class="caption mb-3">Students, teachers and great organisations</div>

      <div class="d-flex align-center flex-wrap topics">
        <div
          v-for="(contributor, index) in content.contributors"
          :key="index"
          style="min-width: 50%"
        >
          <elements-user-avatar :user="contributor" />
        </div>
      </div>
    </v-card>

    <v-card flat class="pa-8 mb-5" color="purple lighten-5">
      <div class="text-h3 my-3 text-center">
        <span class="text-h2">{{ content.school_count }}</span> schools across
        <span class="text-h2">208</span> local authorities
      </div>
      <div class="caption mb-3 text-center">
        Vote on national & private school polls<br />
        Real people... vouched as students by their school
      </div>
    </v-card>

    <v-card flat class="pt-12 pb-5 mb-5 text-center" color="green lighten-5">
      <div class="text-h2 mb-1 text-center">A new ethos</div>
      <div class="text-h6 text-center px-5">
        You choose what attributes you want to share.
      </div>
      <div
        class="align-center d-flex my-6"
        style="max-width: 300px; margin: auto"
      >
        <div class="mr-3">
          <v-icon color="success" class="mr-1">mdi-check-circle-outline</v-icon>
          Age
        </div>
        <div class="mr-3">
          <v-icon color="success" class="mr-1">mdi-check-circle-outline</v-icon>
          <!-- <v-icon color="error" class="mr-1">mdi-close-circle-outline</v-icon> -->
          Gender
        </div>
        <div>
          <v-icon color="success" class="mr-1">mdi-check-circle-outline</v-icon>
          Sex
        </div>
        <div>
          <v-icon color="success" class="mr-1">mdi-check-circle-outline</v-icon>
          Language
        </div>
      </div>

      <div class="caption mb-3 text-center px-5">
        No data is captured without your explicit consent. Not even Google
        Analytics!
      </div>
    </v-card>

    <!-- <v-card flat outlined class="pa-4 mb-5">
      <div class="text-h3">Popular groups</div>
      <div class="caption mb-3">
        Build communities to identify with and filter results
      </div>
      <div class="d-flex align-center flex-wrap topics">
        <div v-for="(group, index) in content.groups" :key="index">
          <elements-user-avatar :user="group" />
        </div>
      </div>
    </v-card> -->

    <v-card flat outlined class="pa-4 mb-5">
      <div class="text-h3 mb-2">Topics currently trending</div>
      <elements-topics-block v-if="content.topics" :topics="content.topics" />
    </v-card>

    <v-card
      v-if="!content.active_user"
      flat
      outlined
      class="pa-4 mb-5 text-center"
    >
      <div class="text-h2 mb-2">Create your free account</div>
      <v-btn to="/register" class="text-wrap" color="success">Sign me up</v-btn>
    </v-card>

    <!-- {{ content }} -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      content: [],
      error: false,
      defaultTitle: "Schdoo",
      defaultDescription:
        "The home of what under 18s think and you ask the questions.",
      defaultImage: "https://schdoo.com/schdoo.png",
    };
  },
  mounted() {
    this.getContent();
    this.updateMetaTags();
  },
  methods: {
    getContent() {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "/home",
        })
        .then(() => {
          this.content = this.$store.getters.apiResponse.data;
        });
    },
    updateMetaTags() {
      document.title = this.defaultTitle;

      const metaTags = [
        { property: "og:url", content: window.location.href },
        { property: "og:title", content: this.defaultTitle },
        {
          property: "og:description",
          content: this.defaultDescription,
        },
        {
          property: "og:image",
          content: this.defaultImage,
        },
        {
          name: "description",
          content: this.defaultDescription,
        },
        { property: "twitter:domain", content: this.defaultTitle },
        { property: "twitter:url", content: window.location.href },
        { name: "twitter:title", content: this.defaultTitle },
        {
          name: "twitter:description",
          content: this.defaultDescription,
        },
        {
          name: "twitter:image",
          content: this.defaultImage,
        },
      ];

      metaTags.forEach((tag) => {
        this.updateMetaTag(tag);
      });
    },
    updateMetaTag(tag) {
      let metaElement = document.querySelector(
        `meta[${tag.property ? "property" : "name"}="${
          tag.property || tag.name
        }"]`
      );
      if (metaElement) {
        metaElement.setAttribute("content", tag.content);
      } else {
        metaElement = document.createElement("meta");
        if (tag.property) {
          metaElement.setAttribute("property", tag.property);
        } else {
          metaElement.setAttribute("name", tag.name);
        }
        metaElement.setAttribute("content", tag.content);
        document.head.appendChild(metaElement);
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  max-width: 890px;
}
</style>
