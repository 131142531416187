<template>
  <div style="min-height: 70px" class="mt-3">
    <div class="text-h6">{{ text }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Array of target sentences
      mainText: [
        "Are you worried about climate change?",
        "Do you eat healthy?",
        "Did you see Taylor Swift's concert?",
        "Who should be the next class president?",
        "Have you ever been bullied?",
        "Does pineapple belong on pizza?",
      ],
      text: "", // dynamically typed text
      sentenceIndex: 0, // to track the current sentence
      charIndex: 0, // to track the current character
      delay: 100, // delay between each character
      typingSpeed: 100, // typing speed delay
      pauseBetweenSentences: 2000, // pause after each sentence
    };
  },
  mounted() {
    this.startTypingEffect();
  },
  methods: {
    startTypingEffect() {
      this.typeCharacter();
    },
    typeCharacter() {
      const currentSentence = this.mainText[this.sentenceIndex];

      if (this.charIndex < currentSentence.length) {
        // Add the next character to the text
        this.text += currentSentence[this.charIndex];
        this.charIndex++;

        // Continue typing the next character
        setTimeout(this.typeCharacter, this.typingSpeed);
      } else {
        // Finished typing the current sentence, pause before clearing
        setTimeout(() => {
          this.resetForNextSentence();
        }, this.pauseBetweenSentences);
      }
    },
    resetForNextSentence() {
      // Reset text and indexes for the next sentence
      this.text = "";
      this.charIndex = 0;

      // Move to the next sentence or loop back to the first
      this.sentenceIndex = (this.sentenceIndex + 1) % this.mainText.length;

      // Start typing the next sentence
      this.typeCharacter();
    },
  },
};
</script>

<style scoped></style>
